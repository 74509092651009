import './app.css';
import { Observer, useLocalObservable } from 'mobx-react';
import axios from 'axios';
import loadingMessages from './loadingMessages';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

// Use for local development
// const downloadBaseUrl = 'http://localhost:3001';
const downloadBaseUrl = '';

function App() {
  const controller = useLocalObservable(() => ({
    loading: false,
    download: null,
    error: null,
    async submit(e) {
      this.error = null;
      this.download = null;
      this.loading = true;
      this.loadingMessage = null;

      this.loadingMessage = loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
      this.loadingMessageInterval = setInterval(() => {
        this.loadingMessage = loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
      }, 6000);

      const formData = new FormData();
      formData.append('song', e.target.files[0]);
      try {
        const {data: { key, song }} = await axios.post('/api/split', formData)
        this.download = { key, song };
      } catch (e) {
        this.error = (
          <>
            <div>Sorry! Something went wrong.</div>
            <div>Please try again later.</div>
            </>
        )
      } finally {
        this.loading = false;
        clearInterval(this.loadingMessageInterval);
      }
    },
  }));

  return (
    <>
      <div className="splitit">
        <Observer>{() => {
          if (!controller.download) {
            return null
          }
          return (
            <div style={{display: 'none'}}>
              <iframe title="Downloader" src={`${downloadBaseUrl}/api/download/${controller.download.key}/${controller.download.song}`}/>
            </div> 
          )}}
        </Observer>

        <div className="container">
          <div className="app">
            <header className="header">
              Splitit
            </header>
            <Observer>{() => {
              if (!controller.error) {
                return null;
              }
              return (
                <Alert className={'error'} severity="error">{controller.error}</Alert>
              )}
            }
            </Observer>

            <Observer>{() => {
              if (controller.loading) {
                return (
                  <div className="loading">
                    <div className={'header'}>Processing...</div>
                    <LinearProgress />
                    <Observer>{() => (
                      <div className={'message'}>{controller.loadingMessage}</div>
                    )}
                    </Observer>
                  </div>
                )
              }
              return (
                <Button className={'button'} size="large" variant="contained" component="label" disabled={controller.loading} startIcon={<MusicNoteIcon />} >
                  Choose song to upload
                  <input hidden id="input" type="file" accept="audio/*" onChange={controller.submit} />
                </Button>
              )
            }}
            </Observer>
          </div>
        </div>
      </div>
      </>
  );
}

export default App;
